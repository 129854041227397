import 'core-js/fn/array/from';

const BUTTON_SELECTOR   = '.js-toggle-menu'
const MENU_SELECTOR     = '.js-menu'
const VISIBLE_CLASSNAME = 'is-visible'
const ANCHOR_SELECTOR   = 'a[href*="#"]'

export default class Menu {
  constructor() {
    this.menuToggler()
    this.anchorScroller()

  }

  menuToggler() {
    Array.from(document.querySelectorAll(BUTTON_SELECTOR)).forEach(el => {
      el.addEventListener('click', () => {
        this.toggleMenu()
      })
    })
  }

  anchorScroller() {
    Array.from(document.querySelectorAll(ANCHOR_SELECTOR)).forEach(el => {
      el.addEventListener('click', (ev) => {
        ev.preventDefault()
        const anchor = el.getAttribute('href').split('#')[1]
        this.scroll(anchor)
        this.toggleMenu(false)
      })
    })
  }
  toggleMenu(state) {
    const menu = document.querySelector(MENU_SELECTOR)
    if (menu) {
      const action = state ? 'add' : (state === undefined ? 'toggle' : 'remove')
      menu.classList[action](VISIBLE_CLASSNAME)
    }
  }

  scroll(anchor) {
    const section = document.getElementById(anchor)

    if (section) {
      section.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    }
  }
}
